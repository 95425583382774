import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core'
import firebase from 'firebase/app'

import ConfiguratorBackgroundImage from 'components/configurator/ConfiguratorBackgroundImage'
import ConfiguratorConfirmationModal from 'components/configurator/ConfiguratorConfirmationModal'
import ConfiguratorHeader from 'components/configurator/ConfiguratorHeader'
import ConfiguratorStepper from 'components/configurator/ConfiguratorStepper'
import Layout from 'components/Layout'
import ScreenCreate from 'components/configurator/screens/ScreenCreate'
import ScreenReview from 'components/configurator/screens/ScreenReview'
import ScreenSubmit from 'components/configurator/screens/ScreenSubmit'

import type { GetInTouchData } from '@/functions/src/index'
import { validateEmail } from 'utils/validation'

import { RequestStatus } from 'types/component/configurator'

import { useRoleStats } from 'hooks/useRolesStats'
import configuratorStore, { resetStore } from 'store/configurator'

const styles = {
  container: 'flex flex-col items-center relative',
  screens: 'w-full max-w-4xl mb-20 pt-8 p-4 md:p-8',
}

const ConfiguratorPage = () => {
  useRoleStats()
  const { contactForm, isError, requestStatus, rolesStats, team } =
    useHookstate(configuratorStore)

  const price = `CHF ${
    team.length <= 4 ? 6 : 8
  },000 per month + CHF 2,000 per hire`

  const onSubmit = async () => {
    isError.set(true)
    if (validateEmail(contactForm.email.get())) {
      requestStatus.set(RequestStatus.LOADING)
      // eslint-disable-next-line import/no-named-as-default-member
      const getInTouch = firebase.functions().httpsCallable('getInTouch')

      try {
        const data: GetInTouchData = {
          comment: contactForm.comment.get(),
          email: contactForm.email.get(),
          name: contactForm.companyName.get(),
          price,
          rolesStats: rolesStats.attach(Downgraded).get() || {},
          team: team.attach(Downgraded).get(),
        }

        await getInTouch(data)
        requestStatus.set(RequestStatus.SUCCESS)
      } catch (error) {
        alert('Something went wrong, please try again.')
      }
    }
  }

  useEffect(() => {
    return () => {
      resetStore()
    }
  }, [])

  return (
    <Layout
      title="Configurator"
      isFooterVisible={false}
      backgroundImage={<ConfiguratorBackgroundImage />}
    >
      <div className={styles.container}>
        <div className={styles.screens}>
          <ConfiguratorHeader />
          <ScreenCreate />
          <ScreenReview price={price} />
          <ScreenSubmit />
        </div>
        <ConfiguratorStepper onSubmit={onSubmit} />
        <ConfiguratorConfirmationModal
          isVisible={requestStatus.get() === RequestStatus.SUCCESS}
        />
      </div>
    </Layout>
  )
}

export default ConfiguratorPage
