import React from 'react'
import { useHookstate } from '@hookstate/core'
import { AnimatePresence, motion } from 'framer-motion'

import { Typography } from '@material-ui/core'
import InputText from 'components/common/InputText'

import configuratorStore from 'store/configurator'

import { ConfiguratorStep } from 'types/component/configurator'

import { setValue } from 'utils/setValue'
import { validateEmail } from 'utils/validation'

import TEXT from 'locales/configurator.json'

const ScreenSubmit = () => {
  const { currentStep, contactForm, isError } = useHookstate(configuratorStore)

  if (currentStep.get() !== ConfiguratorStep.SUBMIT) {
    return null
  }

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, translateY: 20 }}
        animate={{ opacity: 1, translateY: 0 }}
        className="flex flex-col gap-6 w-full md:w-5/6 self-start"
      >
        <div className="flex flex-col sm:flex-row gap-4">
          <InputText
            className="bg-primary-darkest rounded-t-md"
            id="name"
            label={TEXT.step.submit.form.companyName}
            onChange={
              setValue(contactForm.companyName) as React.ChangeEventHandler
            }
            value={contactForm.companyName.get()}
          />
          <InputText
            className="bg-primary-darkest rounded-t-md"
            error={isError.get() && !validateEmail(contactForm.email.get())}
            id="email"
            label={TEXT.step.submit.form.email}
            onChange={setValue(contactForm.email) as React.ChangeEventHandler}
            required
            value={contactForm.email.get()}
          />
        </div>
        <div>
          <Typography variant="caption" className="block h-0 pl-0.5">
            {TEXT.step.submit.form.required}
          </Typography>
          <InputText
            className="bg-primary-darkest rounded-t-md"
            id="comment"
            label={TEXT.step.submit.form.comment}
            onChange={setValue(contactForm.comment) as React.ChangeEventHandler}
            multiline
            rows={6}
            variant="filled"
            value={contactForm.comment.get()}
          />
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default ScreenSubmit
