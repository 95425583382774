import React from 'react'

import useBreakpoints from 'hooks/useBreakpoints'
import { ConfiguratorStep } from 'types/component/configurator'
import { useHookstate } from '@hookstate/core'
import configuratorStore from 'store/configurator'

const backgroundImageStyleDefault: React.CSSProperties = {
  backgroundAttachment: 'fixed',
  backgroundImage: 'url(/222.svg)',
  backgroundRepeat: 'no-repeat',
  opacity: 0.3,
  position: 'absolute',
  width: '100%',
}

const ConfiguratorBackgroundImage = () => {
  const { currentStep } = useHookstate(configuratorStore)
  const { isMobileScreen } = useBreakpoints()

  const isImageVisible =
    isMobileScreen || currentStep.get() === ConfiguratorStep.CREATE

  if (!isImageVisible) {
    return null
  }

  const style = isMobileScreen
    ? {
        ...backgroundImageStyleDefault,
        backgroundPosition: '104% -24px',
        backgroundSize: '180px',
        height: '60px',
      }
    : {
        ...backgroundImageStyleDefault,
        backgroundPosition: '100% 0%',
        backgroundSize: '200px',
        height: '200px',
      }

  return <div style={style} />
}

export default ConfiguratorBackgroundImage
