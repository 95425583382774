import React from 'react'
import { IconButton, Typography } from '@material-ui/core'
// eslint-disable-next-line import/named
import { State } from '@hookstate/core'

import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined'

interface InputNumberIncrementorProps {
  className?: string
  value: State<number>
}

/**
 * @description This component is used to increment and decrement a number. It uses @hookstate/core to manage the number.
 * @param {InputNumberIncrementorProps} props
 */
const InputNumberIncrementor = ({
  className,
  value,
}: InputNumberIncrementorProps) => {
  return (
    <div className={['flex items-center gap-4 md:gap-2', className].join(' ')}>
      <IconButton
        size="small"
        onClick={() => value.set((n: number) => (n > 1 ? n - 1 : 1))}
        disabled={value.get() <= 1}
      >
        <RemoveOutlinedIcon />
      </IconButton>
      <Typography color="textPrimary">{value.get()}</Typography>
      <IconButton size="small" onClick={() => value.set(n => n + 1)}>
        <AddOutlinedIcon />
      </IconButton>
    </div>
  )
}

export default InputNumberIncrementor
