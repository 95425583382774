import React, { ChangeEvent, useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import { SelectProps } from '@material-ui/core/Select'
import { ListSubheader } from '@material-ui/core'

import InputLabel from 'components/common/InputLabel'
import MenuItem from 'components/common/MenuItem'
import { Select } from 'components/common/SingleSelect'

import TEXT from 'locales/configurator.json'

export type MultiSelectOptions = (string | string[])[] | string

interface MultiSelectProps extends SelectProps {
  options: MultiSelectOptions[]
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    checkbox: {
      padding: '0 0.5rem',
    },
  })
)

const asyncQuerySelector = (query: string): Promise<Element> =>
  new Promise(resolve => {
    const select = () => {
      const element = window.document.querySelector(query)
      if (element) resolve(element)
      else setTimeout(select, 300)
    }
    select()
  })

// NOTE: This is a fix to a visual bug in Material-UI.
const useHackyFixForDropdownShift = () => {
  useEffect(() => {
    asyncQuerySelector(
      '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded'
    ).then(dropdown => {
      Object.defineProperty((dropdown as HTMLElement).style, 'top', {
        get() {
          return '16px'
        },
        set() {
          // Do nothing - disable changes made by library
        },
      })
    })
  })
}

/**
 * @description Function handles bulk selection of options in a MultiSelect component.
 * @param event React.ChangeEvent<{ value: unknown }>,
 * @param options MultiSelectOptions
 * @param previousValues T[]
 * @returns T[]
 */
export function handleGroupingChange<T>(
  event: ChangeEvent<{ value: unknown }>,
  options: MultiSelectOptions[],
  previousValues: T[]
) {
  const rawValue = event.target.value as T[]
  const selectedValue = rawValue[rawValue.length - 1]

  const regionNames = options.map(group => `group:${group[0]}` as unknown as T)

  const regionIndex = regionNames.indexOf(selectedValue as T)
  // If region name is clicked:
  if (regionIndex > -1) {
    const region: MultiSelectOptions = options[regionIndex]
    const countries = region[1] as unknown as T[]

    // Remove all the countries in the selection if the selected region with all its countries currently selected
    if (countries.every(country => rawValue.indexOf(country) > -1)) {
      return previousValues.filter(
        (value: T) => countries.indexOf(value) === -1
      )
    } else {
      // Select all options of the region if the region is selected
      return countries
    }
  } else {
    // Select the country if it is clicked
    return event.target.value as T[]
  }
}

const MultiSelect = ({ options, ...props }: MultiSelectProps) => {
  const classes = useStyles()
  const labelId = props.labelId || props.id + '-label'

  useHackyFixForDropdownShift()

  const isMenuWithGrouping = options.length > 0 && Array.isArray(options[0][1])

  return (
    <FormControl variant="filled" className={classes.formControl}>
      <InputLabel id={labelId} className="text-grey">
        {props.label}
      </InputLabel>
      <Select
        {...props}
        error={!props.value && props.error}
        id={props.id}
        labelId={labelId}
        multiple
        renderValue={selected =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (selected as any).length > 1
            ? TEXT.step.create.form.multiple
            : // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (selected as any)[0]
        }
        variant="filled"
      >
        {isMenuWithGrouping
          ? // If a menu is grouped, render the group name and the options
            (options as (string | string[])[][]).map(group => [
              <ListSubheader
                key={`group:${group[0]}` as string}
                value={`group:${group[0]}` as string}
                className="cursor-pointer select-none"
              >
                {group[0]}
              </ListSubheader>,
              ...(group[1] as string[]).map((option: string) => {
                return (
                  <MenuItem key={option} value={option}>
                    <Checkbox
                      checked={(props.value as string[]).indexOf(option) > -1}
                      className={classes.checkbox}
                      color="primary"
                    />
                    <ListItemText primary={option} />
                  </MenuItem>
                )
              }),
            ])
          : // If a menu is not grouped, render the options
            (options as string[]).map(option => {
              return (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    checked={(props.value as string[]).indexOf(option) > -1}
                    className={classes.checkbox}
                    color="primary"
                  />
                  <ListItemText primary={option} />
                </MenuItem>
              )
            })}
      </Select>
    </FormControl>
  )
}

export default MultiSelect
