import React from 'react'
// eslint-disable-next-line import/named
import { State } from '@hookstate/core'

import IconButton from '@material-ui/core/IconButton'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

import AddRoleButton from 'components/configurator/AddRoleButton'
import EstimatedResult from 'components/configurator/EstimatedResult'
import MultiSelect, {
  handleGroupingChange,
} from 'components/common/MultiSelect'
import InputText from 'components/common/InputText'
import InputNumberIncrementor from 'components/common/InputNumberIncrementor'
import SingleSelect from 'components/common/SingleSelect'

import { locationOptions } from 'constants/configurator'
import { setValue } from 'utils/setValue'

import { removePosition } from 'store/configurator'
import { Position, RoleEnum, roles, seniorities } from 'types/team'

import TEXT from 'locales/configurator.json'

type RoleItemEditProps = {
  className?: string
  error: boolean
  isNewPosition: boolean
  isRemovable?: boolean
  position: State<Position>
  onSave: () => void
  quantity: State<number>
  formatedData: Partial<{
    noticePeriod: string
    salaryRange: string
    talentPoolSize: string
  }> | null
}

const RoleItemEdit = ({
  className,
  error,
  isNewPosition,
  isRemovable,
  onSave,
  position,
  quantity,
  formatedData,
}: RoleItemEditProps) => {
  const isFormFilled =
    !!position.role.get() &&
    !!position.seniority.get() &&
    !!position.location.length

  const isListedRole = position.role.get() !== RoleEnum.Other

  const handleLocationChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newLocations = handleGroupingChange(
      event,
      locationOptions,
      position.location.get()
    )

    position.location.set(newLocations)
  }

  return (
    <div className={['flex flex-col w-full p-6', className].join(' ')}>
      <div className="grid sm:grid-cols-3 gap-2 md:gap-6 mb-6">
        <SingleSelect
          error={error}
          id="role"
          items={roles}
          label={TEXT.step.create.form.role}
          onChange={setValue(position.role)}
          value={position.role.get()}
        />
        <SingleSelect
          error={error}
          id="seniority"
          items={seniorities}
          label={TEXT.step.create.form.seniority}
          onChange={setValue(position.seniority)}
          value={position.seniority.get()}
        />
        <MultiSelect
          error={error}
          id="location"
          label={TEXT.step.create.form.location}
          onChange={handleLocationChange}
          options={locationOptions}
          value={position.location.get()}
        />
        {!isListedRole && (
          <>
            <InputText
              id="other-role"
              key={1}
              label={TEXT.step.create.form.otherRole}
              onChange={
                setValue(
                  position.otherRole
                ) as React.ChangeEventHandler<HTMLInputElement>
              }
              value={position.otherRole.get()}
            />
            <InputText
              className="col-span-2"
              id="description"
              key={2}
              label={TEXT.step.create.form.description}
              onChange={
                setValue(
                  position.description
                ) as React.ChangeEventHandler<HTMLInputElement>
              }
              value={position.description.get()}
            />
          </>
        )}
      </div>
      <div
        className={`flex flex-col md:flex-row w-full ${
          isListedRole && isFormFilled ? 'justify-between' : 'justify-end'
        }`}
      >
        {isListedRole && isFormFilled && (
          <div className="flex flex-col md:flex-row justify-between w-full max-w-lg gap-4 md:gap-6">
            <EstimatedResult
              label={TEXT.step.create.estimatedResult.talentSize}
            >
              <div className="flex items-center">
                <span>
                  {formatedData?.talentPoolSize ??
                    TEXT.step.create.estimatedResult.unavailable}
                </span>
              </div>
            </EstimatedResult>
            <EstimatedResult label={TEXT.step.create.estimatedResult.time}>
              <span>
                {formatedData?.noticePeriod ??
                  TEXT.step.create.estimatedResult.unavailable}
              </span>
            </EstimatedResult>
            <EstimatedResult label={TEXT.step.create.estimatedResult.salary}>
              <span>
                {formatedData?.salaryRange ??
                  TEXT.step.create.estimatedResult.unavailable}{' '}
                /{TEXT.step.create.estimatedResult.year}
              </span>
            </EstimatedResult>
          </div>
        )}
        <div className="flex items-center w-full justify-between md:justify-end mt-8 md:mt-0 md:gap-4">
          {isRemovable && (
            <IconButton
              size="small"
              className="w-8 h-8"
              onClick={() => removePosition(position)}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          )}
          <div
            className={`flex items-center justify-between ${
              isRemovable && isFormFilled && 'md:ml-8'
            }`}
          >
            <InputNumberIncrementor value={quantity} className="mr-8" />
            <AddRoleButton
              disabled={!isFormFilled}
              isNewRole={isNewPosition}
              onClick={onSave}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoleItemEdit
