import { MultiSelectOptions } from 'components/common/MultiSelect'
import TEXT from 'locales/configurator.json'

export const locationOptions: MultiSelectOptions[] = [
  [
    'Europe',
    [
      'Switzerland',
      'Germany',
      'UK',
      'Portugal',
      'Spain',
      'Poland',
      'Hungary',
      'Romania',
      'Serbia',
    ],
  ],
  ['North America', ['USA']],
  ['Other', ['Europe', 'Anywhere']],
]

export const stepperButtonText = [
  TEXT.component.stepper.button.next,
  TEXT.component.stepper.button.request,
  TEXT.component.stepper.button.getInTouch,
]
