import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'

import { Seniority } from 'types/team'

import TEXT from 'locales/configurator.json'

interface PositionData {
  location: string
  role: string | undefined
  salaryRange: string
  seniority: Seniority | undefined
  time: string | undefined
}

interface RoleItemReadProps {
  data: PositionData
  isEditable: boolean
  isListedRole: boolean
  setEditMode: () => void
}

const RoleItemRead = ({
  data,
  setEditMode,
  isEditable,
  isListedRole,
}: RoleItemReadProps) => {
  return (
    <div
      className={isEditable ? 'cursor-pointer' : 'cursor-default'}
      onClick={setEditMode}
      onKeyPress={setEditMode}
      role="button"
      tabIndex={0}
    >
      {/* Screen: Desktop */}
      <div className="hidden md:flex w-full justify-between items-center p-4 max-h-16">
        <div className="flex items-center justify-between w-3/5">
          <h3 className="text-heading-3-capitalized text-primary">
            {data.role}
          </h3>
          <div className="display flex justify-between w-1/2 text-body-1 text-white">
            <span>{data.location}</span>
            <span>{data.seniority}</span>
            <span>{data.time}</span>
          </div>
        </div>
        {!isListedRole ? (
          <span className="text-body-1 pr-4">
            {TEXT.step.create.form.unlistedRole}
          </span>
        ) : (
          <div className="flex justify-end items-center">
            <span className="text-body-1 text-primary-dark">
              {data.salaryRange}
            </span>
            {isEditable && (
              <IconButton onClick={setEditMode}>
                <EditOutlinedIcon />
              </IconButton>
            )}
          </div>
        )}
      </div>
      {/* Screen Mobile */}
      <div className="flex md:hidden w-full justify-between relative">
        <div className="flex flex-col p-3 text-body-1">
          <span className="text-primary-dark">{data.role}</span>
          <span className="text-primary-dark">{data.salaryRange}</span>
          <div className="flex gap-3 mt-2">
            <span
              className="text-body-2"
              color={isEditable ? 'error' : 'inherit'}
            >
              {data.location}
            </span>
            <span
              className="text-body-2"
              color={isEditable ? 'error' : 'inherit'}
            >
              {data.seniority}
            </span>
            <span
              className="text-body-2"
              color={isEditable ? 'error' : 'inherit'}
            >
              {data.time}
            </span>
          </div>
        </div>
        {isEditable && (
          <div className="absolute top-0 right-0">
            <IconButton onClick={setEditMode}>
              <EditOutlinedIcon htmlColor="white" />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default RoleItemRead
