import React, { CSSProperties, useState } from 'react'
// eslint-disable-next-line import/named
import { State, useHookstate } from '@hookstate/core'

import RoleItemEdit from 'components/configurator/RoleItemEdit'
import RoleItemRead from 'components/configurator/RoleItemRead'

import configuratorStore, { addPosition } from 'store/configurator'

import { colors, gradients } from 'styles/colors'
import { calculatePositionStats, Formatter } from 'utils/rolesStats'

import { RolesStats } from 'types/rolesStats'
import { Position, RoleEnum } from 'types/team'

import TEXT from 'locales/configurator.json'

type RoleItemWrapperProps = {
  isEditable?: boolean
  isRemovable?: boolean
  position: State<Position>
  rolesStats?: RolesStats
}

const validatePosition = ({ role, seniority, location }: Position) =>
  !!role && !!seniority && location.length !== 0

const classes = {
  wrapper: 'w-full border border-primary-dark rounded-md',
}

const RoleItemWrapper = ({
  isEditable = true,
  isRemovable,
  position,
  rolesStats,
}: RoleItemWrapperProps) => {
  const { currentPositionId } = useHookstate(configuratorStore)
  const quantity = useHookstate(1)
  const [isNewPosition, setIsNewPosition] = useState(true)
  const [isError, setIsError] = useState(false)

  const isEditModeEnabled =
    currentPositionId.get() === position.id.get() && isEditable

  const isListedRole = position.role.get() !== RoleEnum.Other

  const positionStatsRaw = calculatePositionStats(
    position.get(),
    rolesStats || {}
  )
  const positionStats =
    positionStatsRaw && Formatter.formatPositionStats(positionStatsRaw)

  const sanitizedPosition = (position: State<Position>) => {
    const rawData = position.get()

    return {
      ...rawData,
      location:
        rawData.location?.length === 1
          ? rawData.location[0]
          : TEXT.step.create.form.multiple,
      role: isListedRole ? rawData.role : rawData.otherRole,
      salaryRange: positionStats.salaryRange ?? '',
      seniority: rawData.seniority,
      time: isListedRole ? positionStats?.noticePeriod : '',
    }
  }

  const positionData = sanitizedPosition(position)

  const setEditMode = () => {
    currentPositionId.set(positionData.id)
    setIsNewPosition(false)
  }

  const wrapperBackground: CSSProperties = {
    background:
      isEditable && !isEditModeEnabled
        ? gradients.secondary
        : colors.secondary.darkest,
  }

  return (
    <div className={classes.wrapper} style={wrapperBackground}>
      {isEditModeEnabled ? (
        <RoleItemEdit
          error={isError}
          formatedData={positionStats}
          isNewPosition={isNewPosition}
          isRemovable={isRemovable}
          onSave={() => {
            setIsError(true)
            if (validatePosition(position.get())) {
              isNewPosition && addPosition(position, quantity.get() - 1)
              setIsNewPosition(false)
              currentPositionId.set(null)
            }
          }}
          position={position}
          quantity={quantity}
        />
      ) : (
        <RoleItemRead
          data={positionData}
          isEditable={isEditable}
          isListedRole={isListedRole}
          setEditMode={setEditMode}
        />
      )}
    </div>
  )
}

export default RoleItemWrapper
