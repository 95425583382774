import React from 'react'
import Typography from '@material-ui/core/Typography'

type EstimatedResultProps = {
  label: string
  children: React.ReactNode | string
}

const EstimatedResult = ({ children, label }: EstimatedResultProps) => (
  <div className="flex flex-col w-min whitespace-nowrap">
    <Typography variant="caption" color="primary" className="translate-y-1">
      {label}
    </Typography>
    {children}
  </div>
)

export default EstimatedResult
