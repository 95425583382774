import React from 'react'
import { navigate } from 'gatsby'
import { useHookstate } from '@hookstate/core'

import configuratorStore from 'store/configurator'
import { RequestStatus } from 'types/component/configurator'

import TEXT from 'locales/configurator.json'
import Modal from 'components/common/Modal'
import Button from 'components/common/Button'

interface ConfiguratorConfirmationModalProps {
  isVisible: boolean
}

const classes = {
  root: 'flex flex-col justify-between items-center text-center p-6 bg-secondary-darker',
  title: 'font-medium text-xl tracking-tight mb-4',
}

const ConfiguratorConfirmationModal = ({
  isVisible,
}: ConfiguratorConfirmationModalProps) => {
  const { requestStatus } = useHookstate(configuratorStore)

  return (
    <Modal
      className={classes.root}
      isVisible={isVisible}
      onClose={() => requestStatus.set(RequestStatus.DONE)}
      style={{ width: '334px', height: '374px' }}
    >
      <img
        alt=""
        className="self-end"
        src="/222.svg"
        style={{ width: '100px', height: 'auto' }}
      />
      <div className="mt-3">
        <p className={classes.title}>
          {TEXT.component.confirmationModal.title}
        </p>
        <p>{TEXT.component.confirmationModal.description}</p>
      </div>
      <Button className="self-end mb-6" onClick={() => navigate('/')}>
        {TEXT.component.confirmationModal.button.close}
      </Button>
    </Modal>
  )
}

export default ConfiguratorConfirmationModal
