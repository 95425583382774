import React, { MouseEventHandler } from 'react'

import Button from 'components/common/Button'

import TEXT from 'locales/configurator.json'

interface AddRoleButtonProps {
  className?: string
  disabled: boolean
  isNewRole: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}

const AddRoleButton = ({
  className,
  disabled,
  isNewRole,
  onClick,
}: AddRoleButtonProps) => {
  const buttonLabel = isNewRole
    ? TEXT.step.create.button.add
    : TEXT.step.create.button.done

  return (
    <Button
      secondary
      className={['h-full', className].join(' ')}
      disabled={disabled}
      onClick={onClick}
    >
      {buttonLabel}
    </Button>
  )
}

export default AddRoleButton
