import React from 'react'
import { useHookstate } from '@hookstate/core'
import { AnimateSharedLayout, AnimatePresence, motion } from 'framer-motion'

import Button from 'components/common/Button'
import RoleItemList from 'components/configurator/RoleItemList'
import SectionTitle from 'components/common/SectionTitle'

import { ConfiguratorStep } from 'types/component/configurator'

import TEXT from 'locales/configurator.json'
import configuratorStore, { moveStepBackward } from 'store/configurator'

interface ScreenReviewProps {
  price: string
}

const ScreenReview = ({ price }: ScreenReviewProps) => {
  const { currentStep, rolesStats, team } = useHookstate(configuratorStore)

  if (currentStep.get() !== ConfiguratorStep.REVIEW) {
    return null
  }

  return (
    <AnimateSharedLayout>
      <AnimatePresence>
        <motion.div
          className="flex flex-col justify-between gap-14"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
        >
          <div className="flex flex-col">
            <div className="flex items-center w-full justify-between mb-2">
              <SectionTitle position="left">
                {TEXT.step.review.titleSecondary}
              </SectionTitle>
              <Button
                secondary
                className="max-h-8 w-20 self-end"
                onClick={() => moveStepBackward()}
              >
                {TEXT.step.review.button.edit}
              </Button>
            </div>
            <RoleItemList
              rolesStats={rolesStats.get()}
              step={ConfiguratorStep.REVIEW}
              team={team}
            />
          </div>
          <div className="w-full mb-14 md:w-2/3">
            <SectionTitle position="left" className="mb-4">
              {TEXT.step.review.title}
            </SectionTitle>
            <p>{TEXT.step.review.description}</p>
            <p className="text-primary text pt-4">{price}</p>
          </div>
        </motion.div>
      </AnimatePresence>
    </AnimateSharedLayout>
  )
}

export default ScreenReview
