import React from 'react'
// eslint-disable-next-line import/named
import { State } from '@hookstate/core'

import RoleItem from 'components/configurator/RoleItem'

import { ConfiguratorStep } from 'types/component/configurator'
import { RolesStats } from 'types/rolesStats'
import { Position } from 'types/team'

interface RoleItemListProps {
  className?: string
  rolesStats?: RolesStats | null
  step: ConfiguratorStep
  team: State<Position[]>
}

const RoleItemList = ({
  className,
  rolesStats,
  step,
  team,
}: RoleItemListProps) => {
  return (
    <div
      className={[
        'flex flex-col justify-center items-center w-full gap-2',
        className,
      ].join(' ')}
    >
      {!!team.length &&
        team.map(position => (
          <RoleItem
            key={position.id.get()}
            isEditable={step === ConfiguratorStep.CREATE}
            isRemovable={team.length > 1}
            position={position}
            rolesStats={rolesStats || undefined}
          />
        ))}
    </div>
  )
}

export default RoleItemList
