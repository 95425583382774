import React from 'react'
import { useHookstate } from '@hookstate/core'

import Button from 'components/common/Button'
import RoleItemList from 'components/configurator/RoleItemList'

import configuratorStore, { addPosition } from 'store/configurator'

import { ConfiguratorStep } from 'types/component/configurator'

import TEXT from 'locales/configurator.json'

const ScreenCreate = () => {
  const { currentPositionId, currentStep, team, rolesStats } =
    useHookstate(configuratorStore)

  if (currentStep.get() !== ConfiguratorStep.CREATE) {
    return null
  }

  const isAddRoleButtonVisible = currentPositionId.get() === null

  return (
    <div className="flex flex-col w-full mt-6">
      <RoleItemList
        className="mb-4"
        rolesStats={rolesStats.get()}
        step={ConfiguratorStep.CREATE}
        team={team}
      />
      {isAddRoleButtonVisible && (
        <Button
          secondary
          className="w-full"
          style={{ borderRadius: '0.5em', height: '5em' }}
          onClick={() => {
            const lastPosition = team[team.length - 1]
            addPosition(lastPosition)
          }}
        >
          <span className="text-body-1">{TEXT.step.create.button.addRole}</span>
        </Button>
      )}
    </div>
  )
}

export default ScreenCreate
