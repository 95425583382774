import { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core'

import { LocationStats } from 'types/rolesStats'
import { Location } from 'types/team'

import { getRolesStats } from 'utils/getRolesStats'
import configuratorStore from 'store/configurator'

export type UseRolesStats = Partial<Record<Location, LocationStats>> | null

export const useRoleStats = (): UseRolesStats => {
  const { rolesStats } = useHookstate(configuratorStore)

  useEffect(() => {
    getRolesStats()
      .then(result => {
        rolesStats.set(result)
      })
      // TODO: handle error
      // eslint-disable-next-line no-console
      .catch(console.warn)
  }, [])

  return rolesStats.attach(Downgraded).get()
}
