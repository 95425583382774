import React from 'react'
import {
  BaseTextFieldProps,
  createStyles,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core'
import { colors } from 'styles/colors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      borderRadius: '4px 4px 0 0',
      backgroundColor: colors.primary.darkest,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {},
    },
  })
)
interface InputTextProps extends BaseTextFieldProps {
  className?: string
  id: string
  label: string
  value?: string
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
}

const InputText = ({
  className,
  id,
  label,
  variant,
  value,
  onChange,
  ...props
}: InputTextProps) => {
  const classes = useStyles()

  return (
    <TextField
      {...props}
      className={['w-full', className].join(' ')}
      id={id}
      inputProps={{ classes }}
      label={label}
      onChange={onChange}
      value={value}
      variant={variant ?? 'filled'}
    />
  )
}

export default InputText
