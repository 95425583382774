import React from 'react'

import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'
import IconButton from '@material-ui/core/IconButton'

import { ConfiguratorStep } from 'types/component/configurator'
import { useHookstate } from '@hookstate/core'
import configuratorStore, { moveStepBackward } from 'store/configurator'

const ConfiguratorHeader = () => {
  const { currentStep } = useHookstate(configuratorStore)

  if (currentStep.get() === ConfiguratorStep.CREATE) {
    return null
  }

  return (
    <div className="flex items-center mb-10">
      <IconButton
        color="inherit"
        onClick={() => moveStepBackward()}
        className="-translate-x-3"
      >
        <ArrowBackOutlinedIcon fontSize="large" />
      </IconButton>
      <p className="text-subtitle">Back</p>
    </div>
  )
}

export default ConfiguratorHeader
