import firebase from 'firebase'
import type { RolesStats, LocationStats } from 'types/rolesStats'
export const getRolesStats = async (): Promise<RolesStats> => {
  // eslint-disable-next-line import/no-named-as-default-member
  const data = await firebase.firestore().collection('rolesStats').get()
  const docs = data.docs.map(doc => doc.data() as LocationStats)
  const rolesStats: RolesStats = docs.reduce((acc, next) => {
    return { ...acc, [next.location]: next }
  }, {})
  return rolesStats
}
