// Use relative import to work firebase functions
import type { Position } from '../types/team'
import type {
  PositionStats,
  RolesStats,
  SalaryRange,
  TalentPoolSize,
} from '../types/rolesStats'

export const calculatePositionStats = (
  { role, seniority, location: locationArr }: Position,
  rolesStats: RolesStats
): Partial<PositionStats> => {
  if (role && role !== 'Other' && seniority && locationArr.length !== 0) {
    const { noticePeriod, salaryRange, talentPoolSize } = locationArr.reduce<{
      noticePeriod: number
      talentPoolSize?: TalentPoolSize
      salaryRange: {
        start: number
        end: number
      }
    }>(
      (acc, location) => {
        const { noticePeriod, rolesData } = rolesStats[location] ?? {}
        const currentRoleData = rolesData?.[role]
        const currentSalaryRange = currentRoleData?.salary[seniority]

        return {
          noticePeriod: Math.min(noticePeriod ?? Infinity, acc.noticePeriod),
          talentPoolSize:
            (currentRoleData?.talentPoolSize || acc.talentPoolSize) &&
            Math.max(
              currentRoleData?.talentPoolSize ?? 0,
              acc.talentPoolSize ?? 0
            ),
          salaryRange: {
            start: Math.min(
              currentSalaryRange?.start ?? Infinity,
              acc.salaryRange.start
            ),
            end: Math.max(currentSalaryRange?.end ?? 0, acc.salaryRange.end),
          },
        }
      },
      {
        noticePeriod: Infinity,
        talentPoolSize: undefined,
        salaryRange: {
          start: Infinity,
          end: 0,
        },
      }
    )

    return {
      noticePeriod: noticePeriod === Infinity ? undefined : noticePeriod,
      salaryRange: salaryRange.start === Infinity ? undefined : salaryRange,
      talentPoolSize,
    }
  }
  return {}
}
const talentPoolSizeStrings: ('small' | 'medium' | 'large')[] = [
  'small',
  'medium',
  'large',
]

export const Formatter = {
  formatNoticePeriod(noticePeriod: number /* weeks */) {
    if (noticePeriod % 4 === 0 /* full months */) {
      return `${noticePeriod / 4} ${
        noticePeriod / 4 === 1 ? 'month' : 'months'
      }`
    } else {
      return `${noticePeriod} ${noticePeriod === 1 ? 'week' : 'weeks'}}`
    }
  },
  formatSalaryRange(range: SalaryRange, currency = 'EUR') {
    if (currency !== 'EUR')
      throw TypeError(
        `Currency ${currency} is not supported yet. Use EUR instead`
      )
    return `€${Math.round(range.start / 1000) ?? 0},000 - €${
      Math.round(range.end / 1000) ?? 0
    },000`
  },
  formatTalentPoolSize(poolSize: TalentPoolSize) {
    return talentPoolSizeStrings[poolSize]
  },
  formatPositionStats(data: Partial<PositionStats>) {
    return {
      noticePeriod:
        (data.noticePeriod && this.formatNoticePeriod(data.noticePeriod)) ||
        undefined,
      salaryRange: data.salaryRange && this.formatSalaryRange(data.salaryRange),
      talentPoolSize:
        (typeof data.talentPoolSize === 'number' &&
          this.formatTalentPoolSize(data.talentPoolSize)) ||
        undefined,
    }
  },
}
